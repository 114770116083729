import en from "./locales/en.json";
import fr from "./locales/fr.json";
import bg from "./locales/bg.json";
import cs from "./locales/cs.json";
import da from "./locales/da.json";
import de from "./locales/de.json";
import el from "./locales/el.json";
import es from "./locales/es.json";
import et from "./locales/et.json";
import fi from "./locales/fi.json";
import ga from "./locales/ga.json";
import hr from "./locales/hr.json";
import hu from "./locales/hu.json";
import it from "./locales/it.json";
import lt from "./locales/lt.json";
import lv from "./locales/lv.json";
import mt from "./locales/mt.json";
import nl from "./locales/nl.json";
import pl from "./locales/pl.json";
import pt from "./locales/pt.json";
import ro from "./locales/ro.json";
import sk from "./locales/sk.json";
import sl from "./locales/sl.json";
import sv from "./locales/sv.json";

import enLabels from "../qrwebssr/locales/en.json";
import frLabels from "../qrwebssr/locales/fr.json";
import bgLabels from "../qrwebssr/locales/bg.json";
import hrLabels from "../qrwebssr/locales/hr.json";
import csLabels from "../qrwebssr/locales/cs.json";
import daLabels from "../qrwebssr/locales/da.json";
import nlLabels from "../qrwebssr/locales/nl.json";
import elLabels from "../qrwebssr/locales/el.json";
import etLabels from "../qrwebssr/locales/et.json";
import fiLabels from "../qrwebssr/locales/fi.json";
import deLabels from "../qrwebssr/locales/de.json";
import huLabels from "../qrwebssr/locales/hu.json";
import gaLabels from "../qrwebssr/locales/ga.json";
import itLabels from "../qrwebssr/locales/it.json";
import lvLabels from "../qrwebssr/locales/lv.json";
import ltLabels from "../qrwebssr/locales/lt.json";
import mtLabels from "../qrwebssr/locales/mt.json";
import plLabels from "../qrwebssr/locales/pl.json";
import ptLabels from "../qrwebssr/locales/pt.json";
import roLabels from "../qrwebssr/locales/ro.json";
import skLabels from "../qrwebssr/locales/sk.json";
import slLabels from "../qrwebssr/locales/sl.json";
import esLabels from "../qrwebssr/locales/es.json";
import svLabels from "../qrwebssr/locales/sv.json";

export default defineI18nConfig(() => ({
  legacy: false,
  locale: "en",
  messages: {
    en: {
      ...en,
      ...enLabels,
    },
    fr: {
      ...fr,
      ...frLabels,
    },
    bg: {
      ...bg,
      ...bgLabels,
    },
    hr: {
      ...hr,
      ...hrLabels,
    },
    cs: {
      ...cs,
      ...csLabels,
    },
    da: {
      ...da,
      ...daLabels,
    },
    nl: {
      ...nl,
      ...nlLabels,
    },
    el: {
      ...el,
      ...elLabels,
    },
    et: {
      ...et,
      ...etLabels,
    },
    fi: {
      ...fi,
      ...fiLabels,
    },
    de: {
      ...de,
      ...deLabels,
    },
    hu: {
      ...hu,
      ...huLabels,
    },
    ga: {
      ...ga,
      ...gaLabels,
    },
    it: {
      ...it,
      ...itLabels,
    },
    lv: {
      ...lv,
      ...lvLabels,
    },
    lt: {
      ...lt,
      ...ltLabels,
    },
    mt: {
      ...mt,
      ...mtLabels,
    },
    pl: {
      ...pl,
      ...plLabels,
    },
    pt: {
      ...pt,
      ...ptLabels,
    },
    ro: {
      ...ro,
      ...roLabels,
    },
    sk: {
      ...sk,
      ...skLabels,
    },
    sl: {
      ...sl,
      ...slLabels,
    },
    es: {
      ...es,
      ...esLabels,
    },
    sv: {
      ...sv,
      ...svLabels,
    },
  },
}));
